const SLIDER_AREA05 = [
  {
    filename: `img-sanrio-area-5-1.jpg`,
  },
  {
    filename: `img-sanrio-area-5-2.jpg`,
  },
];

const SLIDER_AREA07 = [
  {
    filename: `img-sanrio-area-7-1.jpg`,
  },
  {
    filename: `img-sanrio-area-7-2.jpg`,
  },
];

export const SANRIO_CATEGORIES = [
  {
    number: 1,
    name: 'Dream!ng Music Party', // アトラクション名
    description:
      '音楽に乗って、サンリオのキャラクターと一緒にパーティーへ出かけよう！', // 説明
    description2:
      '楽しい音楽に乗って、好きなサンリオのキャラクターと一緒に、雲の上のお城で開かれるパーティーを目指すゲーム。メロディロードを流れてくるブロックに合わせて、足元のブロックを踏み、音楽と空間をどんどん賑やかにしていこう！', // 説明
    img: 'img-sanrio-area-1.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定
    imgSlider: false,
    caution3:
      '<ul class="list-base mt0 text"><li class="is-11px">お1人様1枚のチケットが必要です。</li><li class="is-11px">本ゲームは1ゲームにつき2名様まで同時に体験いただけます。1名様でのご利用の場合、混雑時は1名様同士で体験いただく場合がございます。</li><li class="is-11px">推奨年齢：6歳以上</li></ul>',
  },
  {
    number: 2,
    name: 'Dream!ng BALL PARADISE', // アトラクション名
    description: '「Dream!ng Ball」で、みんなの「あったらいいな」が溢れ出す！', // 説明
    description2:
      'サンリオのキャラクターと一緒に楽しく遊ぶことで、夢のような空間が、魔法のように現れるマジカルエリア。飛んでくる「Dreaming Heart」に「Dreaming Ball」を当てて、「あったらいいな」が溢れる空間を作って楽しもう！', // 説明
    img: 'img-sanrio-area-2.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定
    imgSlider: false,
    caution3:
      '<ul class="list-base mt0 text"><li class="is-11px">お1人様1枚のチケットが必要です。</li><li class="is-11px">混雑時は、他のグループの方と一緒に体験いただく場合がございます。</li><li class="is-11px">プレイ時にはお履き物を脱いでいただく必要がございます。</li><li class="is-11px">推奨年齢：3歳以上</li></ul>',
  },
  {
    number: 3,
    name: 'Dream!ng にんげんタワーバトル', // アトラクション名
    description:
      'サンリオのキャラクターと一緒に、にんげんタワーを積み上げよう！', // 説明
    description2:
      'メディアなどで人気の「にんげんタワーバトル」が「Dream!ng Park」でしか体験できない、サンリオのキャラクターバージョンとなって登場！思い思いのポージングで、サンリオのキャラクターと一緒にタワーを高く積み上げよう！', // 説明
    img: 'img-sanrio-area-3.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定
    imgSlider: false,
    caution3:
      '<ul class="list-base mt0 text"><li class="is-11px">お1人様1枚のチケットが必要です。</li><li class="is-11px">1組のプレイ人数は4名様までとなります。</li><li class="is-11px">プレイ時にはお履き物を脱いでいただく必要がございます。</li><li class="is-11px">推奨年齢：3歳以上</li></ul>',
  },
  {
    number: 4,
    name: 'Dream!ng SMILES AR SNAP', // アトラクション名
    description:
      '好きなサンリオのキャラクターと一緒に、特別なAR写真が撮れちゃう！', // 説明
    description2:
      '本当にその場にいるかのように、キャラクターがARで出現！好きなサンリオのキャラクターと一緒に、好きなポーズで写真撮影を楽しむことができます。「Dream!ng Park」で遊びつくした思い出を、特別な写真として持ち帰ろう！', // 説明
    img: 'img-sanrio-area-4.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定
    imgSlider: false,
    caution3:
      '<ul class="list-base mt0 text"><li class="is-11px">1組1枚のチケットが必要です。</li><li class="is-11px">1組のプレイ人数は3名様までとなります。</li>',
  },
  {
    number: 5,
    name: 'Dream!ng PHOTO STUDIO', // アトラクション名
    description:
      '「あったらいいな」が詰まった空間で、特別な撮影ができるフォトブース！', // 説明
    description2:
      '「Dream!ng Park」のみんなが集まる、かわいいカフェをイメージした空間で、サンリオグッズや推しグッズを持ち込んで、好きなシチュエーションで撮影したり、ミニフォトブースでぬい撮りを楽しんだり、自由に撮影を楽しむことができます。', // 説明
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定
    imgSlider: true,
    sliderImg: SLIDER_AREA05,
    caution3:
      '<ul class="list-base mt0 text"><li class="is-11px">PHOTO STUDIO の入場には、お1人様1枚のチケットが必要です。</li><li class="is-11px">体験時間は約3分間となります。</li><li class="is-11px">他のグループの方と一緒にご入場いただきます。</li></ul>',
  },
  {
    number: 6,
    name: 'Dream!ng GATE', // アトラクション名
    description: 'サンリオのキャラクターたちが、みんなをお出迎え！', // 説明
    description2:
      'ここは「Dream!ng Park」の入口。色とりどりに光るゲートをくぐると、目の前には「あったらいいな」が詰まった、楽しいコンテンツがいっぱい！サンリオのキャラクターと一緒に、Parkで遊びつくそう！', // 説明
    img: 'img-sanrio-area-6.jpg', // 画像名
    imgSlider: false,
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定
  },
  {
    number: 7,
    name: 'Dream!ng TREE', // アトラクション名
    description: '時計台で、サンリオのキャラクターと友達のように待ち合わせ。', // 説明
    description2:
      '「Dream!ng Park」の象徴となる、大きな木の時計台。まずはこの場所で、キャラクターたちと待ち合わせて記念撮影！1時間ごとに、キャラクターたちのかわいい演出も楽しむことができます。', // 説明
    imgSlider: true,
    sliderImg: SLIDER_AREA07,
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定
  },
];

export const SANRIO_TICKET = [
  {
    ttl: 'プリントクッキー（缶バッジ付き）', // エリア名
    price: '1,200円',
    img: 'img-sanrio-goods-1.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: true,
  },
  {
    ttl: '海苔チップス　2缶', // エリア名
    price: '1,600円',
    img: 'img-sanrio-goods-2.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: true,
  },
  {
    ttl: 'シークレットラムネ（8種）', // エリア名
    price: '700円',
    img: 'img-sanrio-goods-4.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: true,
  },
  {
    ttl: 'シークレットアクリルスタンド（8種）', // エリア名
    price: '550円',
    img: 'img-sanrio-goods-7.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: true,
  },
  {
    ttl: 'ぬいぐるみ（8種）', // エリア名
    price: '3,080円',
    img: 'img-sanrio-goods-8.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: true,
  },
  {
    ttl: 'マスコットキーチェーン（8種）', // エリア名
    price: '1,980円',
    img: 'img-sanrio-goods-9.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: true,
  },
];

export const SANRIO_MERUHEN = [
  {
    ttl: '自分だけのおなまえホルダーがつくれる！', // エリア名
    img: 'img-sanrio-meruhen-1.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: false,
    descUrl: 'https://e-zofukuoka.com/news/sanrio/2023122625047/',
  },
];

export const SANRIO_NOVELTY = [
  {
    name: 'オリジナルステッカープレゼント', // エリア名
    img: 'img-novelty-sticker.jpg', // 画像名
    present:
      'ドリーミングパーク内でコンテンツチケットを1枚ご利用につき1枚プレゼント',
    present2: false,
    period:
      ' 12月21日（木）～配布予定数終了まで<br>※配布予定枚数終了しました。',
    periodtable: false,
    attention:
      '※ステッカーは実際の仕様、デザイン、色味など変更になる場合がございます。',
    attention2: '※コンテンツチケット利用時にスタッフよりお渡しいたします。',
    attention3: '※ブラインドパッケージのため、中身を選ぶことはできません。',
    attention4:
      '※配布予定数分が無くなりましたら終了となります。あらかじめご了承ください。',
    hvImg: true,
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定
    description: '※配布予定枚数終了しました。<br />詳細はこちら',
  },
];

export const SANRIO_BABY = [
  {
    ttl: 'ハローキティバスポンチョ', // エリア名
    price: '5,280円',
    img: 'sanrio-baby-poncho.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: false,
  },
  {
    ttl: 'マイメロディ洗えるベビー用マスコット', // エリア名
    price: '3,520円',
    img: 'sanrio-baby-mascot.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: false,
  },
  {
    ttl: 'ミニメリー', // エリア名
    price: '1,980円',
    img: 'sanrio-baby-minimary.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: false,
  },
  {
    ttl: 'シナモロール洗える絵本', // エリア名
    price: '3,960円',
    img: 'sanrio-baby-book.jpg', // 画像名
    colorClass: 'floorbc-sanrio-area', // シャドウカラー設定w
    imgSlider: true,
    descWrap: true,
    noPointer: true,
    original: false,
  },
];
