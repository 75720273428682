/**
 * Type: 投稿コンポーネント
 * What: ドリパー4件の投稿リスト(TOP固定を除く)
 */
import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Card from './Card';
import { Btn, Button, Text, Title } from '../Btn';

const PostListComponent = () => {
  const QueryData = graphql`
    {
      allWordpressPost(
        filter: { categories: { elemMatch: { slug: { eq: "dreaming_park" } } } }
        limit: 4
      ) {
        nodes {
          title
          path
          excerpt
          date(formatString: "YYYY.MM.DD")
          sticky
          id
          featured_media {
            id
            source_url
          }
          categories {
            id
            slug
            name
          }
        }
      }
    }
  `;
  const postData = useStaticQuery(QueryData);
  const posts = postData.allWordpressPost.nodes;
  console.log(posts);
  return (
    <>
      {posts.length ? (
        <section className="section bs-topics-sanrio border-none">
          <div className="container">
            <h2 className="headline">
              <span className="sanrio-text">Topics</span>
            </h2>
            <div className="columns is-multiline">
              {posts.map((post) => (
                <div className="column is-3" key={post.id}>
                  <Card {...post} />
                </div>
              ))}
            </div>
            <div className="btn-wrap mb-5">
              <Button href="/news/dreaming_park/">一覧をみる</Button>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default PostListComponent;
