/**
 * Type: ページ
 * What: サンリオ
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { scroller, animateScroll as scroll } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Button } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import PostListComponent from '../components/PostList/PostList.dreaming_park';
import mainImg from '../images/sanrio/main-sanrio.jpg';
import mainImg2 from '../images/sanrio/main-sanrio-sp.jpg';
import {
  ContentsList,
  ContentsListGoods,
} from '../components/ContentsListSanrio';
import {
  SANRIO_CATEGORIES,
  SANRIO_TICKET,
  SANRIO_NOVELTY,
  SANRIO_MERUHEN,
  SANRIO_BABY,
} from '../config/Sanrio';
import useMediaQuery from '../util/useMediaQuery';

const pageTitle = 'Sanrio characters Dream!ng Park（ドリパー）';
const pageDescription =
  'サンリオのキャラクターと友だちになって遊びつくす、共遊型コミュニケーションパーク';
const pageSlug = 'sanrio';
const pageLogo = 'logo-sanrio.png';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: mainImg,
    noAnimation: true,
  },
];

const sliderDataobjSp = [
  {
    filename: mainImg2,
    noAnimation: true,
  },
];

const mq = useMediaQuery;

// Data Props Template
const Template = ({ data }) => {
  const ankerLinkScroll = (e, elem, offset) => {
    e.preventDefault();
    let $offset = offset || -160;
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      $offset = offset || -110;
    }
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  const url = data.site.siteMetadata;

  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
  }, []);

  // モーダルstate
  const [isAreaModal, setAreaModal] = useState(-1);

  // 注意事項モーダル
  const [isAttentionModal, setAttentionModal] = useState(-1);

  // ノベルティモーダル
  const [isNoveltyModal, setNoveltyModal] = useState(-1);

  // エリアモーダル展開
  const onClickAreaModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setAreaModal(num);
  };

  // 注意事項モーダル展開
  const onClickAttentionModal = () => {
    setAttentionModal(1);
  };

  // ノベルティモーダル展開
  const onClickNoveltyModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setNoveltyModal(num);
  };

  // エリアモーダル閉じる
  const onCloseAreaModal = () => {
    setAreaModal(-1);
  };

  // 注意事項モーダル閉じる
  const onCloseAttentionModal = () => {
    setAttentionModal(-1);
  };

  // ノベルティモーダル閉じる
  const onCloseNoveltyModal = () => {
    setNoveltyModal(-1);
  };

  // エリアモーダルコンテンツ
  const AreaModalContent = () => {
    const img =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].img;
    const name =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].name;
    const colorClass =
      SANRIO_CATEGORIES[isAreaModal] &&
      SANRIO_CATEGORIES[isAreaModal].colorClass;
    const price =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].price;
    const count =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].count;
    const time =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].time;
    const age =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].age;
    const height =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].height;
    const weight =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].weight;
    const caution1 =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].caution1;
    const caution2 =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].caution2;
    const caution3 =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].caution3;
    const caution4 =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].caution4;
    const ticketUrl =
      SANRIO_CATEGORIES[isAreaModal] && SANRIO_CATEGORIES[isAreaModal].url;
    const description =
      SANRIO_CATEGORIES[isAreaModal] &&
      SANRIO_CATEGORIES[isAreaModal].description2;
    const imgSlider =
      SANRIO_CATEGORIES[isAreaModal] &&
      SANRIO_CATEGORIES[isAreaModal].imgSlider;
    const sliderImg =
      SANRIO_CATEGORIES[isAreaModal] &&
      SANRIO_CATEGORIES[isAreaModal].sliderImg;

    return (
      <div className={`modal e-modal ${isAreaModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseAreaModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseAreaModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
              {imgSlider ? (
                <SimpleSwiper data={sliderImg} />
              ) : (
                <Image filename={img || 'now-printing.jpg'} />
              )}
            </figure>
          </div>
          <div className="e-modal-content sanrio-bg-yellow">
            <h3 className="ttl c-4">{name}</h3>
            <p>{description}</p>
            {/* <h4>アトラクション注意事項</h4> */}
            <ul>
              {price && (
                <li>
                  料金:
                  <b>{price}</b>
                </li>
              )}
              {time && (
                <li>
                  体験時間:
                  <b>{time}</b>
                </li>
              )}
              {count && (
                <li>
                  体験人数:
                  <b>{count}</b>
                </li>
              )}
              {age && (
                <li>
                  年齢制限:
                  <b>{age}</b>
                </li>
              )}
              {height && (
                <li>
                  身長制限:
                  <b>{height}</b>
                </li>
              )}
              {weight ? (
                <li>
                  体重制限:
                  <b>{weight}</b>
                </li>
              ) : null}
            </ul>
            {caution1 && (
              <>
                <h5>
                  <span>ご利用いただけない方</span>
                </h5>
                <p>{caution1}</p>
                {caution4 && (
                  <div dangerouslySetInnerHTML={{ __html: caution4 }} />
                )}
              </>
            )}
            {caution2 && (
              <>
                <h5>
                  <span>以下の症状の方はご利用できません</span>
                </h5>
                <p>{caution2}</p>
              </>
            )}
            {caution3 && (
              <>
                <h5>
                  <span>注意事項</span>
                </h5>
                <p dangerouslySetInnerHTML={{ __html: caution3 }} />
              </>
            )}
            {ticketUrl && (
              <div className="btn-wrap mt-5">
                <Button
                  className="btn-buy"
                  href="https://ticket.e-zofukuoka.com/#/order?id=E5KT8EKDXWYL3JT6IJZU6ADEADGYNSAG6SASAN387H3VU0MBQ258O7DOK3D3GLVI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  チケット購入
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // 注意事項モーダルコンテンツ
  const AttentionModalContent = () => (
    <div
      className={`modal e-modal ${isAttentionModal > -1 ? 'is-active' : ''}`}
    >
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="modal-background" onClick={onCloseAttentionModal} />
      <button
        type="button"
        className="delete"
        aria-label="close"
        data-sal="zoom-in"
        data-sal-delay="300"
        data-sal-easing="ease-out-expo"
        onClick={onCloseAttentionModal}
      />
      <div className="modal-content c-floorbc-sanrio-area">
        <div className="e-modal-content sanrio-bg-yellow">
          <h4 className="ttl c-4">Dream!ng Music Party</h4>
          <ul className="mb20 mt10 list-base">
            <li>お1人様1枚のチケットが必要です。</li>
            <li>
              本ゲームは1ゲームにつき2名様まで同時に体験いただけます。1名様でのご利用の場合、混雑時は1名様同士で体験いただく場合がございます。
            </li>
            <li>推奨年齢：6歳以上</li>
          </ul>
          <h4 className="ttl c-4">Dream!ng BALL PARADISE</h4>
          <ul className="mb20 mt10 list-base">
            <li>お1人様1枚のチケットが必要です。</li>
            <li>
              混雑時は、他のグループの方と一緒に体験いただく場合がございます。
            </li>
            <li>プレイ時にはお履き物を脱いでいただく必要がございます。</li>
            <li>推奨年齢：3歳以上</li>
          </ul>
          <h4 className="ttl c-4">Dream!ng にんげんタワーバトル</h4>
          <ul className="mb20 mt10 list-base">
            <li>お1人様1枚のチケットが必要です。</li>
            <li>1組のプレイ人数は4名様までとなります。</li>
            <li>プレイ時にはお履き物を脱いでいただく必要がございます。</li>
            <li>推奨年齢：3歳以上</li>
          </ul>
          <h4 className="ttl c-4">Dream!ng SMILES AR SNAP</h4>
          <ul className="mb20 mt10 list-base">
            <li>
              <span className="attention">1組1枚</span>のチケットが必要です。
            </li>
            <li>1組のプレイ人数は3名様までとなります。</li>
          </ul>
          <h4 className="ttl c-4">Dream!ng PHOTO STUDIO </h4>
          <ul className="mb20 mt10 list-base">
            <li>PHOTO STUDIO の入場には、お1人様1枚のチケットが必要です。</li>
            <li>体験時間は約3分間となります。</li>
            <li>他のグループの方と一緒にご入場いただきます。</li>
          </ul>
        </div>
      </div>
    </div>
  );

  // ノベルティモーダルコンテンツ
  const NoveltyModalContent = () => {
    const img =
      SANRIO_NOVELTY[isNoveltyModal] && SANRIO_NOVELTY[isNoveltyModal].img;
    const name =
      SANRIO_NOVELTY[isNoveltyModal] && SANRIO_NOVELTY[isNoveltyModal].name;
    const colorClass =
      SANRIO_NOVELTY[isNoveltyModal] &&
      SANRIO_NOVELTY[isNoveltyModal].colorClass;
    const present =
      SANRIO_NOVELTY[isNoveltyModal] && SANRIO_NOVELTY[isNoveltyModal].present;
    const period =
      SANRIO_NOVELTY[isNoveltyModal] && SANRIO_NOVELTY[isNoveltyModal].period;
    const periodtable =
      SANRIO_NOVELTY[isNoveltyModal] &&
      SANRIO_NOVELTY[isNoveltyModal].periodtable;
    const attention =
      SANRIO_NOVELTY[isNoveltyModal] &&
      SANRIO_NOVELTY[isNoveltyModal].attention;
    const attention2 =
      SANRIO_NOVELTY[isNoveltyModal] &&
      SANRIO_NOVELTY[isNoveltyModal].attention2;
    const attention3 =
      SANRIO_NOVELTY[isNoveltyModal] &&
      SANRIO_NOVELTY[isNoveltyModal].attention3;
    const attention4 =
      SANRIO_NOVELTY[isNoveltyModal] &&
      SANRIO_NOVELTY[isNoveltyModal].attention4;
    const present2 =
      SANRIO_NOVELTY[isNoveltyModal] && SANRIO_NOVELTY[isNoveltyModal].present2;

    return (
      <div
        className={`modal e-modal ${isNoveltyModal > -1 ? 'is-active' : ''}`}
      >
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseNoveltyModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseNoveltyModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
              <Image filename={img || 'now-printing.jpg'} />
            </figure>
          </div>
          <div className="e-modal-content sanrio-bg-yellow">
            <h3 className="ttl c-4 mb10">{name}</h3>
            {present && (
              <div className="mb30">
                <h5 className="mb10">
                  <span>プレゼント条件</span>
                </h5>
                <p className="mb0">{present}</p>
                {present2 && <p>※メルヘン工房でのお会計分を除く</p>}
              </div>
            )}
            {period && (
              <>
                <h5 className="mb10">
                  <span>期間</span>
                </h5>
                <p
                  className="mb30"
                  dangerouslySetInnerHTML={{ __html: period }}
                />
              </>
            )}
            {periodtable && (
              <div className="table-base park-ticket new-table ticket-table mb30">
                <table className="price-table">
                  <tbody>
                    <tr>
                      <th className="sanrio-bg-purple tac">開始日</th>
                      <th className="sanrio-bg-purple tac">終了日</th>
                      <th className="sanrio-bg-purple tac">キャラクター</th>
                    </tr>
                    <tr>
                      <td>12月21日（木）</td>
                      <td>12月26日（火）</td>
                      <td>シナモロール</td>
                    </tr>
                    <tr>
                      <td>12月27日（水）</td>
                      <td>2024年1月2日（火）</td>
                      <td>ポムポムプリン</td>
                    </tr>
                    <tr>
                      <td>1月3日（水）</td>
                      <td>1月9日（火）</td>
                      <td>クロミ</td>
                    </tr>
                    <tr>
                      <td>1月10日（水）</td>
                      <td>1月16日（火）</td>
                      <td>バッドばつ丸</td>
                    </tr>
                    <tr>
                      <td>1月17日（水）</td>
                      <td>1月23日（火）</td>
                      <td>ハローキティ</td>
                    </tr>
                    <tr>
                      <td>1月24日（水）</td>
                      <td>1月30日（火）</td>
                      <td>マイメロディ</td>
                    </tr>
                    <tr>
                      <td>1月31日（水）</td>
                      <td>2月6日（火）</td>
                      <td>ハンギョドン</td>
                    </tr>
                    <tr>
                      <td>2月7日（水）</td>
                      <td>2月13日（火）</td>
                      <td>ポチャッコ</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {attention && (
              <>
                <h5 className="mb10">
                  <span>注意事項</span>
                </h5>
                <p className="mb10">{attention}</p>
                {attention2 && <p className="mb10">{attention2}</p>}
                {attention3 && <p className="mb10">{attention3}</p>}
                {attention4 && <p className="mb10">{attention4}</p>}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />

      <style>
        {`
            @media screen and (max-width: 767px) {
              .col-content .is-size-7.sp{
                font-size: 10px !important;
              }
            }
        `}
      </style>

      <section className="pageMain bs-4 pb-0">
        <div className="kv-wrap">
          <div className="kv-inner pc ">
            <SimpleSwiper data={sliderDataobj} />
            <p className="credit">
              &copy; 2023 SANRIO CO.,LTD. APPROVAL NO.SP641008
            </p>
          </div>
          <div className="kv-inner sp">
            <SimpleSwiper data={sliderDataobjSp} />
            <p className="credit">
              &copy; 2023 SANRIO CO.,LTD. APPROVAL NO.SP641008
            </p>
          </div>
          <div className="container mb0 sp-mb60">
            <div className="inner tac">
              <div className="pageMain-header">
                <div className="floorNo c-sanrio">
                  <span>7</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src={pageLogo} alt={pageTitle} />
                </h2>
              </div>
              <p className="has-text-align-center has-text-weight-bold mt-6 text is-3">
                「Dream!ng
                Park（ドリパー）」は、みんなの「あったらいいな」が詰まった、夢のようなPark。
                <br />
                色とりどりに光るゲートをくぐると、目の前に広がる不思議な空間。
                <br />
                大きな時計台の前まで進むと、サンリオのキャラクターたちがお出迎えしてくれます。
              </p>
              <p className="has-text-align-center has-text-weight-bold mt-5 text is-3">
                まるで魔法にかかったかのようなこのParkで、サンリオのキャラクターたちと、楽しいゲームに夢中になったり、かわいい空間で一緒に写真を撮影したり、思い出をグッズにして持ち帰ったり。みんなから溢れる笑顔で、Parkはキラキラと輝き出します。
              </p>
              <p className="has-text-align-center has-text-weight-bold mt-5 mb-6 text is-3">
                サンリオのキャラクターと友だちになって遊びつくす、共遊型コミュニケーションパーク「Dream!ng
                Park」
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section bs-topics-sanrio pt-0">
        {/* {url.ticketReady === '1' ? (
          <div
            className="btn-wrap mb-6"
            data-sal="slide-up"
            data-sal-easing="ease-out-expo"
          >
            <Button
              className="btn-buy"
              href="https://ticket.e-zofukuoka.com/#/order?id=E5KT8EKDXWYL3JT6IJZU6ADEADGYNSAG6SASAN387H3VU0MBQ258O7DOK3D3GLVI"
              target="_blank"
              rel="noopener noreferrer"
            >
              チケットを探す
            </Button>
          </div>
        ) : null} */}
        <div
          className="btn-wrap anker-button-wrap mb-0 sanrio-btns"
          data-sal="slide-up"
          data-sal-easing="ease-out-expo"
        >
          <a
            href="#sanrio-topics"
            className="btn-regular"
            onClick={(e) => ankerLinkScroll(e, 'sanrio-topics')}
            onKeyDown={(e) => ankerLinkScroll(e, 'sanrio-topics')}
          >
            <span>Topics</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 18.438 14.47"
              className="icon-anker"
            >
              <path
                id="anker-arrow.svg"
                className="cls-1"
                d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                transform="translate(-384.281 -2431.59)"
              />
            </svg>
          </a>
          {/* <a
            href="#sanrio-event"
            className="btn-regular"
            onClick={(e) => ankerLinkScroll(e, 'sanrio-event')}
            onKeyDown={(e) => ankerLinkScroll(e, 'sanrio-event')}
          >
            <span>イベント情報</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 18.438 14.47"
              className="icon-anker"
            >
              <path
                id="anker-arrow.svg"
                className="cls-1"
                d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                transform="translate(-384.281 -2431.59)"
              />
            </svg>
          </a> */}
          <a
            href="#sanrio-area"
            className="btn-regular"
            onClick={(e) => ankerLinkScroll(e, 'sanrio-area')}
            onKeyDown={(e) => ankerLinkScroll(e, 'sanrio-area')}
          >
            <span>コンテンツ</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 18.438 14.47"
              className="icon-anker"
            >
              <path
                id="anker-arrow.svg"
                className="cls-1"
                d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                transform="translate(-384.281 -2431.59)"
              />
            </svg>
          </a>
          <a
            href="#sanrio-goods"
            className="btn-regular"
            onClick={(e) => ankerLinkScroll(e, 'sanrio-goods')}
            onKeyDown={(e) => ankerLinkScroll(e, 'sanrio-goods')}
          >
            <span>オリジナルグッズ・メルヘン工房</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 18.438 14.47"
              className="icon-anker"
            >
              <path
                id="anker-arrow.svg"
                className="cls-1"
                d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                transform="translate(-384.281 -2431.59)"
              />
            </svg>
          </a>
          <a
            href="#sanrio-baby"
            className="btn-regular"
            onClick={(e) => ankerLinkScroll(e, 'sanrio-baby')}
            onKeyDown={(e) => ankerLinkScroll(e, 'sanrio-baby')}
          >
            <span>Sanrio baby（サンリオベビー）</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 18.438 14.47"
              className="icon-anker"
            >
              <path
                id="anker-arrow.svg"
                className="cls-1"
                d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                transform="translate(-384.281 -2431.59)"
              />
            </svg>
          </a>
          <a
            href="#sanrio-price"
            className="btn-regular"
            onClick={(e) => ankerLinkScroll(e, 'sanrio-price')}
            onKeyDown={(e) => ankerLinkScroll(e, 'sanrio-price')}
          >
            <span>料金・注意事項</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 18.438 14.47"
              className="icon-anker"
            >
              <path
                id="anker-arrow.svg"
                className="cls-1"
                d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                transform="translate(-384.281 -2431.59)"
              />
            </svg>
          </a>
          <a
            href="#sanrio-attention"
            className="btn-regular"
            onClick={(e) => ankerLinkScroll(e, 'sanrio-attention')}
            onKeyDown={(e) => ankerLinkScroll(e, 'sanrio-attention')}
          >
            <span>施設概要</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 18.438 14.47"
              className="icon-anker"
            >
              <path
                id="anker-arrow.svg"
                className="cls-1"
                d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                transform="translate(-384.281 -2431.59)"
              />
            </svg>
          </a>
        </div>
      </section>

      <div id="sanrio-topics">
        <PostListComponent />
      </div>

      {/* <section className="section bs-topics-sanrio">
        <div className="container mb0 sp-mb60">
          <div className="inner mb0">
            <h2 className="headline" id="sanrio-event">
              <span className='sanrio-text'>イベント情報</span>
            </h2>
          </div>
        </div>
      </section> */}

      <section className="section bs-topics-sanrio">
        <div className="container mb0">
          <div className="inner mb0">
            <h2 className="headline" id="sanrio-area">
              <span className="sanrio-text">エリア紹介</span>
            </h2>
            <div className="sc-columns now-event">
              <div
                className="image mb-6"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <Image filename="sanrio-map.jpg" />
                <p className="credit">
                  &copy; 2023 SANRIO CO.,LTD. 著作（株）サンリオ
                </p>
              </div>
              <div className="sc-columns now-event mb80">
                {SANRIO_CATEGORIES.map((categories, index) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div
                    className={`col col-4 ${categories.colorClass}`}
                    key={categories.name}
                    data-modal={index}
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    onClick={(e) => onClickAreaModal(e)}
                  >
                    {categories.number && (
                      <div className="floorNo">
                        <span
                          data-sal="zoom-in"
                          data-sal-delay="300"
                          data-sal-easing="ease-out-expo"
                        >
                          {categories.number}
                        </span>
                      </div>
                    )}
                    <ContentsList {...categories} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section bs-topics-sanrio">
        <div className="container mb0 sp-mb60 pr-0 pl-0">
          <div className="inner mb0">
            <h2 className="headline" id="sanrio-goods">
              <span className="sanrio-text">ドリパーオリジナルグッズ</span>
            </h2>
            <div className="sc-columns sc-columns-normal">
              {SANRIO_TICKET.map((categories, index) => (
                <div
                  className={`col ${categories.colorClass} sanrio-bg-yellow`}
                  data-modal={index}
                  key={categories.name}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListGoods {...categories} />
                </div>
              ))}
            </div>
            <p className="has-text-align-right is-size-7">
              ※価格はすべて税込です。
            </p>
            <p className="has-text-align-right is-size-7 mt-1">
              ※画像はイメージです。
            </p>
          </div>
        </div>
      </section>

      <section className="section bs-topics-sanrio" id="sanrio-meruhen">
        <div className="container mb0 sp-mb60 pr-0 pl-0">
          <div className="inner mb0">
            <h2 className="headline">
              <span className="sanrio-text">メルヘン工房</span>
              <br />
              <span className="u-pc:fz-16 u-sp:fz-10">
                ドリパーオリジナルパーツもあるよ！
              </span>
            </h2>
            <div className="sc-columns sc-columns-normal">
              {SANRIO_MERUHEN.map((categories, index) => (
                <div
                  className={`col ${categories.colorClass} sanrio-bg-yellow`}
                  data-modal={index}
                  key={categories.name}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListGoods {...categories} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section bs-topics-sanrio">
        <div className="container mb0 sp-mb60">
          <div className="inner mb0">
            <h2 className="headline">
              <span className='sanrio-text'>メルヘン工房</span>
            </h2>
            <p className='text is-4 mb30 fwb  has-text-align-center'>お好きなパーツを組み合わせて自分だけのオリジナルおなまえホルダーが作れます！</p>
            <div className="wrap-sp mb30">
              <div className="col6">
                <div className="col6-inner ">
                  <div
                    className="image"
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                  >
                    <Image filename="img-sanrio-goods-6.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* サンリオベビー */}
      <section className="section bs-topics-sanrio" id="sanrio-baby">
        <div className="container mb0 sp-mb60">
          <div className="inner mb0">
            <h2 className="headline">
              <span className="sanrio-text">
                Sanrio baby
                <br className="u-sp" />
                （サンリオベビー）
              </span>
            </h2>

            <p className="u-pc:ta-c">
              <b>
                ベビーギフトブランド「Sanrio
                baby（サンリオベビー）」※九州エリア取り扱い3店舗目
              </b>
            </p>

            <div
              className="image mb-6 u-mt-40"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Image filename="sanrio-baby-visual.jpg" />
            </div>

            <div className="sc-columns sc-columns-normal u-mt-40">
              {SANRIO_BABY.map((categories, index) => (
                <div
                  className={`col ${categories.colorClass} sanrio-bg-yellow`}
                  data-modal={index}
                  key={categories.name}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListGoods {...categories} />
                </div>
              ))}
            </div>
            <p className="has-text-align-right is-size-7">
              ※販売商品は予告なく変更となる場合がございます。また、上記販売商品も売り切れ、欠品となる場合がございます。
            </p>
            <p className="has-text-align-right is-size-7 mt-1">
              ※画像はイメージです。
            </p>
            <p className="has-text-align-right is-size-7 mt-1">
              ※価格はすべて税込です。
            </p>
          </div>
        </div>
      </section>

      <section className="section bs-topics-sanrio">
        <div className="container mb0 sp-mb60">
          <div className="inner mb0">
            <h2 className="headline" id="sanrio-price">
              <span className="sanrio-text">Follow us</span>
            </h2>
            <ul className="u-d-f opt-center u-gap-20">
              <li className="">
                <a
                  href="https://twitter.com/sanrio_dreaming?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3Asanrio_dreaming%7Ctwcon%5Es2A"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="icon-tw.png"
                    alt="twitter"
                    target="_blank"
                    width="40px"
                    height="40px"
                  />
                </a>
              </li>
              <li className="">
                <a
                  href="https://www.instagram.com/sanrio_dreamingpark/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="ig.svg"
                    alt="instagram"
                    target="_blank"
                    width="40px"
                    height="43px"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section bs-topics-sanrio">
        <div className="container mb0">
          <div className="inner mb0">
            <h2 className="headline" id="sanrio-price">
              <span className="sanrio-text">チケット情報</span>
            </h2>
            <div className="table-base park-ticket new-table">
              <table>
                <tbody>
                  <tr>
                    <th className="sanrio-bg-yellow">入場料金</th>
                    <td>
                      <p className="fwb">無料</p>
                    </td>
                  </tr>
                  <tr>
                    <th className="sanrio-bg-yellow">コンテンツプレイ料金</th>
                    <td>
                      <p className="fwb mb10">コンテンツ共通券：500円／1枚</p>
                      <p className="fwb mb10">
                        コンテンツ共通券3枚セット：1,300円
                      </p>
                      <p className="fwb mb10">
                        コンテンツ共通券5枚セット：1,600円
                      </p>
                      <ul className="list-base">
                        <li className="mb10">
                          チケット1枚につき、5つのコンテンツから1つをお選びいただけます。
                        </li>
                        <li className="mb10">
                          <span className="attention">
                            「Dream!ng SMILES AR
                            SNAP」は1組1枚、その他のコンテンツは、お1人様につき1枚のチケットが必要です。
                          </span>
                        </li>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <li className="mb10">
                          その他、各コンテンツの
                          <a
                            className="tx-link"
                            onClick={() => onClickAttentionModal()}
                          >
                            注意事項
                          </a>
                          をご確認の上、チケットをご購入ください。
                        </li>
                        <li className="mb10">
                          コンテンツ共通券は当日限り有効です。
                        </li>
                        <li className="mb10">
                          12歳以下のお子様は、保護者の同伴が必要です。
                        </li>
                        <li>
                          3歳以下のお子様は同伴者のチケットで一緒にご利用いただけます。（※3歳以下はチケット不要、1枚につき2名まで可能）
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th className="sanrio-bg-yellow">購入方法</th>
                    <td>
                      <p className="mb30 sp-mb20">
                        BOSS E・ZO FUKUOKA
                        専用チケットサイトまたは当日券売機にてお買い求めいただけます。
                      </p>
                      <div
                        className="btn-wrap sp-mb10"
                        data-sal="slide-up"
                        data-sal-easing="ease-out-expo"
                      >
                        <Button
                          className="btn-buy"
                          href="https://ticket.e-zofukuoka.com/#/order?id=7CPYEPJ9RLU2WWO6H4K03XCLWNX9Y93QH6NZ87G1CGV67A6J2ZU37ONDLWCB5W29"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          購入はこちら
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="sanrio-bg-yellow">チケットの利用方法</th>
                    <td>
                      <p className="mb10 list-mark text is-14px lh-7">
                        ①券売機または専用チケットサイトにて「コンテンツ共通券」を購入します
                        ※複数枚ご購入の場合でも、QRコードが1つ発行されます
                      </p>
                      <p className="mb10 list-mark text is-14px lh-7">
                        ②最初に遊ぶコンテンツで、スタッフに「コンテンツ共通券」購入時に発行されるQRコードをご提示ください
                      </p>
                      <p className="mb10 list-mark text is-14px lh-7">
                        ③複数枚を購入した場合、残りの枚数分の「コンテンツ共通券」をお渡しします
                      </p>
                      <p className="list-mark text is-14px lh-7">
                        ④2回目以降は、各コンテンツのスタッフに「コンテンツ共通券」をお渡しください
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="tac">
              グッズ販売は免税手続きの対象です。詳細は
              <a href="/tax-free/" className="tx-link">
                こちら
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="section bs-topics-sanrio">
        <div className="container mb0">
          <h2 className="headline" id="sanrio-price">
            <span className="sanrio-text">注意事項</span>
          </h2>
          <div className="inner-slim mb0 sanrio-bg-yellow">
            <article>
              <ul className="list-base">
                <li className="mb10">
                  12歳以下のお子様は、保護者の同伴が必要です。
                </li>
                <li className="mb10">
                  お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。
                </li>
                <li className="mb10">
                  館内状況により、ご入場およびご利用をお待ちいただく場合がございます。混雑時は入場規制をする場合がございます。入場規制をしている間は、チケット購入者を優先的にご案内いたします。
                </li>
                <li className="mb10">
                  体調不良・泥酔状態でのご入場はお断りしております。
                </li>
                <li className="mb10">
                  当施設内での怪我、損失、損害、トラブルなどにつきまして、弊社の責めに帰すべき事由がある場合を除き、弊社は一切の責任を負いません。故障、メンテナンスなどの理由により予告なく一部コンテンツの稼働を中止する場合がございます。
                </li>
                <li className="mb10">
                  お手回り品の放置はご遠慮ください。放置されたお荷物は拾得物としてお預かりさせていただくことがございます。
                  通路や共有スペースの専有、列への割り込み、物品などの販売や展示、その他迷惑行為や危険な行為は禁止です。
                </li>
                <li className="mb10">
                  エリア内への食べ物の持ち込み、コンテンツご利用中の飲食は禁止です。
                </li>
                <li className="mb10">
                  スタッフの指示に従っていただけない場合は、入場をお断りすることがございます。{' '}
                </li>
              </ul>
            </article>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container mb0 sp-mb60">
          <div className="inner mb0">
            <h2 className="headline" id="sanrio-attention">
              <span className="sanrio-text">施設概要</span>
            </h2>
            <div className="table-base park-ticket new-table">
              <table>
                <tbody>
                  <tr>
                    <th className="sanrio-bg-yellow">名称</th>
                    <td>
                      <p>Sanrio characters Dream!ng Park</p>
                    </td>
                  </tr>
                  <tr>
                    <th className="sanrio-bg-yellow">開業日</th>
                    <td>
                      <p>2023年12月21日（木）</p>
                    </td>
                  </tr>
                  <tr>
                    <th className="sanrio-bg-yellow">開催場所</th>
                    <td>
                      <p className="mb10">福岡県福岡市中央区地行浜2-2-6</p>
                      <p>BOSS E・ZO FUKUOKA 7F</p>
                    </td>
                  </tr>
                  <tr>
                    <th className="sanrio-bg-yellow">主催</th>
                    <td>
                      <p>福岡ソフトバンクホークス株式会社</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className="inner-slim sanrio-bg-yellow sanrio-border-vertical">
          <h4 className="title is-5">注意事項</h4>
          <article>
            <ul className="list-base">
              <li>
                当施設内での怪我、損失、損害、トラブルなどにつきまして、弊社は一切の責任を負いません。
              </li>
              <li>
                小学生以下のお子様のご入場には、保護者の同伴が必要です。
              </li>
              <li>
                営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。
              </li>
              <li>
                チケットの転売は禁止しており、転売にて購入いただいたチケットではご利用頂けず、その場合も当館は一切の責任を負いません。
              </li>
              <li>
                お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。
              </li>
              <li>
                会場内での喫煙、アメ・ガムを含む飲食はお断りしております。
              </li>
              <li>
                会場内にトイレ・休憩室はございません
              </li>
              <li>
                体調不良・泥酔状態でのご入場はお断りしております。
              </li>
              <li>
                全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください
              </li>
              <li>
                本イベント会場内は、補助犬を含み動物を連れての入場はお断りしております。
              </li>
              <li>
                事前承諾のない商業目的の撮影や取材はお断りしております。
              </li>
            </ul>
            <h4 className="ttl-strip sanrio-border-left">持ち込み禁止物</h4>
            <p>
              危険物、臭気物、生花など
              <br />
              ペットボトル、水筒以外の蓋が無いドリンク類
              <br />
              その他、スタッフが危険であると判断した物品
            </p>
          </article>
        </div> */}
      </section>
      <AreaModalContent />
      <AttentionModalContent />
      <NoveltyModalContent />
    </Layout>
  );
};

export default Template;
