/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import { Link } from 'gatsby';
import React from 'react';
import Image from '../util/Image';
import { Button } from './Btn';
import SimpleSwiper from './Slider.fade';

export const List = (props) => (
  <div className="col-inner">
    <div className="col-image">
      {props.label ? <span className="tag is-food">{props.label}</span> : null}
      <figure className="image">
        {props.imgSlider ? (
          <SimpleSwiper data={props.sliderImg} />
        ) : (
          <Image filename={props.img ? props.img : 'now-printing.jpg'} />
        )}
      </figure>
    </div>
    <div
      className={`col-content sanrio-bg-yellow ${
        props.bkClass ? props.bkClass : ''
      }`}
    >
      {props.categories === 'team-lab' ? (
        <p className="ttl-cate">{props.theme}</p>
      ) : null}
      <h3 className="ttl">{props.name}</h3>
      {props.categories !== 'team-lab' ? (
        <p
          className="text"
          dangerouslySetInnerHTML={{ __html: props.description }}
        />
      ) : null}
    </div>
  </div>
);

export const GoodsList = (props) => (
  <div className="col-inner">
    <div className="col-image">
      <figure className="image">
        <Image filename={props.img ? props.img : 'now-printing.jpg'} />
      </figure>
    </div>
    {props.descWrap && (
      <div className="col-box">
        <div
          className={`col-content sanrio-bg-yellow ${
            props.tac ? 'has-text-align-center' : ''
          }`}
        >
          {props.ttl && (
            <h3 className="ttl">
              <p className="ttl-text">{props.ttl}</p>
              {props.original && <p className="new">E・ZOオリジナル</p>}
            </h3>
          )}
          {props.ttl === 'ミニメリー' && (
            <>
              <p className="is-size-7 pc mb-2">
                ※ミニメリーの取り付けマスコットは別売りです。
              </p>
              <p className="is-size-7 sp">
                ※ミニメリーの取り付けマスコットは別売りです。
              </p>
            </>
          )}
          {props.desc && <p className="mt-1">{props.desc}</p>}
          {props.price && <p className="mt-1">{props.price}</p>}
          {props.attention1 && (
            <p className="mt-1 is-size-7">{props.attention1}</p>
          )}
          {props.attention2 && (
            <p className="mt is-size-7">{props.attention2}</p>
          )}
          {props.ticketUrl && (
            <div className="btn-wrap mt-5">
              <Button
                href={props.ticketUrl}
                className="btn-buy"
                target="_blank"
              >
                体験チケット購入
              </Button>
            </div>
          )}
          {props.ticketUrl2 && (
            <div className="btn-wrap mt-5">
              <Button
                href={props.ticketUrl2}
                className="btn-buy"
                target="_blank"
              >
                入場券付き体験チケット購入
              </Button>
            </div>
          )}
          {props.descUrl && (
            <div className="btn-wrap mt-5">
              <Button href={props.descUrl} className="btn-regular flex">
                詳細はこちら
              </Button>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);

export const ContentsList = (props) => (
  <>
    {props.link ? (
      <Link to={props.link}>
        <List {...props} />
      </Link>
    ) : (
      <>
        {props.hvImg ? (
          <div className="hv-link">
            <List {...props} />
          </div>
        ) : (
          <div className="no-link">
            <List {...props} />
          </div>
        )}
      </>
    )}
  </>
);

export const ContentsListGoods = (props) => (
  <>
    {props.noPointer ? (
      <div className="no-pointer">
        <GoodsList {...props} />
      </div>
    ) : (
      <div className="no-link">
        <GoodsList {...props} />
      </div>
    )}
  </>
);
